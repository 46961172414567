import React from 'react'
import LayoutGlobal from '../components/LayoutGlobal'
import Seo from '../components/Seo'
import TogglePrice from '../components/TogglePrice'
import { Link } from 'gatsby'
import { FaCheckCircle } from "react-icons/fa"

export default function Cennik() {
  return (
    <LayoutGlobal>

      <Seo 
        title="Cennik"
        description="Aktualne ceny usług obowiązujące w Studio Medyczno-Treningowym."
        url="https://www.fizjopersonalny.pl/cennik"
      />

      <div className='price_box'>
        <h1>Cennik</h1>

        <section>
          <div className='price_content'>
            <h2>Usługi jednorazowa</h2>
            <p>Opcja przeznaczona dla osób, które cenią sobie elastyczność wyboru. Podane ceny obowiązują za godzinę zegarową tj. <b>60 minut</b>.</p>
            <ul>
              <li>Wizyta konsultacyjna <em>250 zł</em></li>
              <li>Fizjoterapia <em>250 zł</em></li>
              <li>Trening medyczny <em>250 zł</em></li>
              <li>Trening personalny <em>250 zł</em></li>
              <li>Masaż leczniczy <em>250 zł</em></li>
            </ul>
            <br />
            <p>Korzystając z wizyty konsultacyjnej, fizjoterapii lub dowolnego treningu, otrzymasz zestaw zaleceń/ćwiczeń odpowiadających Twoim indywidualnym potrzebom.</p>
          </div>
          <div className='price_button'>
            <Link to="/kontakt" className='button'>Umów wizytę</Link>
          </div>
        </section>

        <section>
          <div className='price_content'>
            <h2>Pakiety</h2>
            <p>Przeznaczone dla osób planujących uzyskanie długotrwałego efektu terapeutycznego i/lub treningowego.</p>
          </div>

          <div className='bundle_box'>
            <div className='bundle_item'>
              <div className='bundle_name'>brązowy</div>
              <div className='bundle_price'>690<em>zł</em></div>
              <p>Dla szukająych wyjątkowego prezentu dla bliskich.</p>
              <ul>
                <li><FaCheckCircle /> liczba godzin: <em>3</em></li>
                <li><FaCheckCircle /> cena za godzinę: <em>230 zł</em></li>
                <li><FaCheckCircle /> ważność: <em>1 miesiąc</em></li>
                <li><FaCheckCircle /> konsultacja gratis: <em>nie</em></li>
              </ul>
            </div>
            <div className='bundle_item'>
              <div className='bundle_name'>srebrny</div>
              <div className='bundle_price'>1100<em>zł</em></div>
              <p>Dla szukającyh optymalnego wsparcia zdrowotnego.</p>
              <ul>
                <li><FaCheckCircle /> liczba godzin: <em>5</em></li>
                <li><FaCheckCircle /> cena za godzinę: <em>220 zł</em></li>
                <li><FaCheckCircle /> ważność: <em>6 miesięcy</em></li>
                <li><FaCheckCircle /> konsultacja gratis: <em>nie</em></li>
              </ul>
            </div>
            <div className='bundle_item'>
              <div className='bundle_best'>
                <span>popularny</span>
              </div>
              <div className='bundle_name'>złoty</div>
              <div className='bundle_price'>2000<em>zł</em></div>
              <p>Dla chcących osiągać konkretne cele i efekty.</p>
              <ul>
                <li><FaCheckCircle /> liczba godzin: <em>10</em></li>
                <li><FaCheckCircle /> cena za godzinę: <em>200 zł</em></li>
                <li><FaCheckCircle /> ważność: <em>9 miesięcy</em></li>
                <li><FaCheckCircle /> konsultacja gratis: <em>tak</em></li>
              </ul>
            </div>
            <div className='bundle_item'>
              <div className='bundle_name'>diamentowy</div>
              <div className='bundle_price'>3600<em>zł</em></div>
              <p>Dla planujących długofalową i systematyczną współpracę.</p>
              <ul>
                <li><FaCheckCircle /> liczba godzin: <em>20</em></li>
                <li><FaCheckCircle /> cena za godzinę: <em>180 zł</em></li>
                <li><FaCheckCircle /> ważność: <em>12 miesięcy</em></li>
                <li><FaCheckCircle /> konsultacja gratis: <em>tak</em></li>
              </ul>
            </div>
          </div>

          <p>Usługi w ramach pakietu realizowane są tylko i wyłącznie na terenie Studia. W cenę wliczone jest ułożenie indywidualnie dobranego planu treningowego / rehabilitacyjnego dostarczanego w formie elektronicznej.</p>

          <div className='price_button'>
            <Link to="/kontakt" className='button'>Umów wizytę</Link>
          </div>
        </section>

        <section>
          <div className='price_content'>
            <h2>Płatności</h2>
            <p>Usługę realizowaną w Studio rozliczysz, wykorzystując następujące metody płatności:</p>

            <div className='price_toggle'>
              <TogglePrice title="Gotówka">
                <p>Płatność bezpośrednia dostępna tylko w Studio.</p>
              </TogglePrice>

              <TogglePrice title="Terminal / telefon / smartwatch">
                <p>Płatność bezpośrednia z wykorzystaniem terminala płatniczego, obsługująca:</p>
                <ul>
                  <li>- płatność kartą (VISA, MasterCard, UnionPay, Diners Club, JCB);</li>
                  <li>- płatność telefonem i smartwatch (ApplePay, GooglePay, SamsungPay);</li>
                  <li>- płatność systemem BLIK.</li>
                </ul>
              </TogglePrice>

              <TogglePrice title="Przelew na konto">
                <p>Płatność z wykorzystaniem standardowego przelewu bankowego na konto firmowe. Przy korzystaniu z tej opcji, wykorzystaj poniższe dane:</p>
                <ul>
                  <li>mBank: 63 1140 2004 0000 3302 7879 5415</li>
                  <li>Łukasz Kaleńczuk - FizjoPersonalny</li>
                  <li>ul. Wrzeciono 63a/28, 01-950 Warszawa</li>
                </ul>
              </TogglePrice>
            </div>

          </div>
        </section>

      </div>

    </LayoutGlobal>
  )
}